import { conversation } from 'services';
import { API_CONVERSATION_CONTEXTS } from 'utils/constants';

const getBrandTalkAsRead = (data) => {
  return conversation.post(API_CONVERSATION_CONTEXTS, data);
};

const getAttendanceListService = ({
  page,
  size,
  start,
  end,
  sort,
  status,
  feedback,
  reason,
  username
}) => {
  return conversation.get(API_CONVERSATION_CONTEXTS, {
    params: {
      page,
      size,
      type: 'all',
      start,
      end,
      sort,
      status,
      feedback,
      reason,
      username
    }
  });
};

const getSearchUserHistory = (params) => {
  return conversation.get(API_CONVERSATION_CONTEXTS, { params });
};

const getListOfDistinctUsers = (current, sortField, sortOrder, start, end) => {
  const sort = sortOrder === 'descend' ? 'desc' : 'asc';

  return conversation.get(`${API_CONVERSATION_CONTEXTS}/distinctUsers`, {
    params: {
      start: start ? start.valueOf() : start,
      end: end ? end.valueOf() : end,
      page: current,
      sort: sortField ? `${sortField},${sort}` : sortField
    }
  });
};

export {
  getBrandTalkAsRead,
  getAttendanceListService,
  getSearchUserHistory,
  getListOfDistinctUsers
};
