import { conversation } from 'services';
import { API_CONVERSATION_INTENTS } from 'utils/constants';

const getIntentionsDownload = () => {
  return conversation.get(`${API_CONVERSATION_INTENTS}/download`, {
    responseType: 'blob'
  });
};

export { getIntentionsDownload };
