export default function (socket = { client: null, connected: false, subscribed: [] }, action) {
  let index;
  switch (action.type) {
    case 'SET_STOMP_CLIENT':
      return {
        ...socket,
        client: action.payload,
        connected: action.payload ? socket.connected : false
      };

    case 'SET_STOMP_CLIENT_CONNECTED':
      return { ...socket, connected: action.payload };

    case 'ADD_CONVERSATION_TO_SUBSCRIBED':
      return { ...socket, subscribed: [...socket.subscribed, action.payload] };

    case 'UNSUBSCRIBE_CONVERSATION':
      index = socket.subscribed.findIndex((sub) => {
        return sub.id === action.payload;
      });
      if (index !== -1) {
        const sub = socket.subscribed[index];
        sub.unsubscribe();
        socket.subscribed.splice(index, 1);
      }
      return { ...socket, subscribed: [...socket.subscribed] };

    default:
      return socket;
  }
}
