import * as jwtDecode from 'jwt-decode';

export const getUserInfo = () => {
  const token = localStorage.getItem('authToken');

  if (process.env.REACT_APP_MOCK_ENABLE === 'true') {
    if (token) {
      return jwtDecode(token).sub;
    }

    return 'invalido';
  }

  return 'admin';
};

export const getRoleInfo = () => {
  const token = localStorage.getItem('authToken');

  if (process.env.REACT_APP_MOCK_ENABLE === 'true') {
    return ['ROLE_ADM'];
  }

  if (token) {
    return jwtDecode(token).auth;
  }

  return 'invalido';
};
