const theme = {
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
      green: '#00ff00',
      blue: '#0000ff',
      purple: '#800080',
      gray: '#808080'
    },

    primary: {
      light: '#2d9afe',
      main: '#106ec9',
      dark: '#163564'
    },

    secondary: {
      light: '#E0E0E0',
      main: '#999999',
      dark: '#333333',
      dark2: '#2e3133'
    },

    error: {
      light: '#FF4353',
      light2: '#e74052',
      main: '#dc143c',
      dark: '#8b0000'
    },

    warning: {
      light: '#fd9c0b',
      main: '#eb910c',
      dark: '#ff4500'
    },

    info: {
      light: '#6791fb',
      light2: '#677adc',
      main: '#53acff',
      dark: '#4d8ac9',
      dark2: '#203b56',
      dark3: '#0f263b'
    },

    neutral: {
      main: '#c4c4c4',
      dark: '#717171'
    },

    text: {
      primary: '#212121'
    },

    success: {
      light: '#42b8a8',
      light2: '#3cb371',
      light3: '#529914',
      main: '#188200',
      dark: '##149800',
      dark2: '#258a2a',
      dark3: '#21a628'
    },

    linearGradientBlue: {
      light: '95.27deg, #2d9afe 0%, #53acff 99.44%',
      light2: 'linear-gradient(90deg, #2689D9 0%, #61ACE8 89.04%, #FFFFFF 178.81%)',
      main: '87.62deg, #106ec9 -25.11%, #1882e7 52.55%, #1d8efa 102.33%',
      dark: '90.45deg, #2631A7 -1.37%, #6771DC 105.22%',
      dark2:
        'linear-gradient(90deg, #3264AC 0%, rgba(50, 100, 172, 0.684179) 89.04%, rgba(50, 100, 172, 0) 178.81%)'
    },

    linearGradientGreen: {
      light: 'to right, #42b8a8, #86d5ca',
      main: 'linear-gradient(90deg, #2394AC 0%, rgba(56, 155, 176, 0.684179) 89.04%, rgba(108, 184, 200, 0) 178.81%)',
      dark: 'to right, #21a628, #258a2a'
    },

    linearGradientOrange: {
      main: 'to right, #eb910c, #fd9c0b'
    }
  },
  shadow: {
    '1dp':
      '0 0 2px 0 rgba(210, 210, 210, 0.14), 0 2px 2px 0 rgba(210, 210, 210, 0.12), 0 1px 3px 0 rgba(210, 210, 210, 0.20)',
    '2dp':
      '0 2px 4px 0 rgba(210, 210, 210,0.14), 0 3px 4px 0 rgba(210, 210, 210,0.12), 0 1px 5px 0 rgba(210, 210, 210,0.20)',
    '3dp':
      '0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 4px 0 rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
    '4dp':
      '0 2px 4px 0 rgba(0,0,0,0.14), 0 4px 5px 0 rgba(0,0,0,0.12), 0 1px 10px 0 rgba(0,0,0,0.20)',
    '6dp':
      '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px 0 rgba(0,0,0,0.20)',
    '8dp':
      '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 3px rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.20)',
    '9dp':
      '0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12),  0 5px 6px 0 rgba(0,0,0,0.20)',
    '12dp':
      '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px 0 rgba(0,0,0,0.20)',
    '16dp':
      '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px 0 rgba(0,0,0,0.20)',
    '24dp':
      '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px 0 rgba(0,0,0,0.20)',
    'shadow-1':
      'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))'
  }
};

export default theme;
