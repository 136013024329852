import messagesData from './messages.json';
import proactiveMessageData from './proactiveMessage.json';
import unhandledReasonsData from './unhandledReasons.json';
import statusData from './status.json';
import disctinctUserData from './disctinctUser.json';
import reasonsData from './reasons.json';
import totalMessagesByMonth from './totalMessagesByMonth.json';
import totalStatusContextsByDate from './totalStatusContextsByDate.json';
import graphReasonsData from './graphReasons.json';
import feedbacksData from './feedbacks.json';
import graphFeedbacksData from './graphFeedbacks.json';
import graphTotalUnhandledMessagesByDateData from './graphTotalUnhandledMessagesByDate.json';
import totalContextsByMonth from './totalContextsByMonth.json';
import totalMessagesByDate from './totalMessagesByDate.json';
import totalMessagesByHour from './totalMessagesByHour.json';
import contexts from './contexts.json';
import grapMessages from './grapMessages.json';
import users from './users.json';
import totalUsersByDate from './totalUsersByDate.json';
import wordCloud from './wordCloud.json';
import contextsTableData from './contextsTable.json';
import totalContextsByDate from './totalContextsByDate.json';

const feedbacks = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(feedbacksData));
};

const disctinctUser = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(disctinctUserData));
};

const graph = (req, res, ctx) => {
  switch (req.url.searchParams.get('type')) {
    case 'reasons':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(graphReasonsData));
    case 'totalMessagesByHour':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalMessagesByHour));
    case 'feedbacks':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(graphFeedbacksData));
    case 'wordCloud':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(wordCloud));
    case 'totalUsersByDate':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalUsersByDate));
    case 'contexts':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(contexts));
    case 'users':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(users));
    case 'messages':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(grapMessages));
    case 'totalMessagesByDate':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalMessagesByDate));
    case 'totalMessagesByMonth':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalMessagesByMonth));
    case 'totalContextsByDate':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalContextsByDate));
    case 'totalContextsByMonth':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalContextsByMonth));
    case 'totalUnhandledMessagesByDate':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(graphTotalUnhandledMessagesByDateData));
    case 'totalStatusContextsByDate':
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalStatusContextsByDate));
    default:
      return res(ctx.delay(2000), ctx.status(200), ctx.json(totalMessagesByMonth));
  }
};

const messages = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(messagesData));
};

const status = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(statusData));
};

const reasons = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(reasonsData));
};

const proactiveMessage = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(proactiveMessageData));
};

const unhandledReasons = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(unhandledReasonsData));
};

const destroyProactiveMessage = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(204), ctx.json({}));
};

const createProactiveMessage = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(201), ctx.json({}));
};

const contextsTable = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(contextsTableData));
};

export default {
  graph,
  messages,
  proactiveMessage,
  unhandledReasons,
  status,
  reasons,
  feedbacks,
  disctinctUser,
  destroyProactiveMessage,
  createProactiveMessage,
  contextsTable
};
