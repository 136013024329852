import { conversation } from 'services';
import { API_CONVERSATION_MESSAGES } from 'utils/constants';

const updateUnhandledReason = (message, unhandledReason) => {
  const messageCustom = message;
  messageCustom.unhandledMessage = messageCustom.unhandledMessage || {};
  messageCustom.unhandledMessage.unhandledReason = unhandledReason;

  return conversation.put(`${API_CONVERSATION_MESSAGES}/${messageCustom.id}`, messageCustom);
};

export { updateUnhandledReason };
