import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ContainerSpinner } from './styles';

function Loading() {
  return (
    <ContainerSpinner>
      <LoadingOutlined style={{ fontSize: '75px' }} spin />
      <br />
    </ContainerSpinner>
  );
}

export default Loading;
