import styled from 'styled-components';

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 48vh;
  background: ${(props) => props.theme.palette.common.white};
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  flex-direction: column;
  line-height: 48px;
  font-size: 32px;
  color: ${(props) => props.theme.palette.primary.light};
`;
