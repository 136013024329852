import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    p {
        margin: 0;
    }
    
    .ant-layout.ant-layout-has-sider {
        min-width: 100%;
        min-height: 100vh;
    }
    
    .lblTituloAlerta {
        font-size: 25px;
        color: ${(props) => props.theme.palette.common.gray};
    }

    .swal2-styled.swal2-confirm.btnConfirmarAlerta {
        background-color: ${(props) => props.theme.palette.info.dark2};
        padding-bottom: 6px;
        padding-top: 6px;
        padding-right: 30px;
        padding-left: 30px;
        font-size: 15px;
    }

    .btnConfirmarAlerta:hover {
        background-color: ${(props) => props.theme.palette.info.dark3};
    }

    .btnConfirmarAlerta:focus {
        box-shadow: none;
        background-color: none;
        outline: 0;
    }

    .swal2-styled.swal2-cancel.btnCancelarAlerta {
        background: none;
        color: ${(props) => props.theme.palette.common.gray};
        border: 1px solid gray;
        padding-bottom: 5px;
        padding-top: 5px;
        padding-right: 30px;
        padding-left: 30px;
        font-size: 15px;
    }

    .swal2-styled.swal2-cancel.btnCancelarAlerta:hover {
        background-color: ${(props) => props.theme.palette.error.dark};
        color: ${(props) => props.theme.palette.common.white};
        border-color: ${(props) => props.theme.palette.error.dark};
    }

    .btnCancelarAlerta:focus {
        box-shadow: none;
        background-color: none;
        outline: 0;
    }
`;

export default GlobalStyle;
