import React from 'react';
import { useLocalStorage } from '@react-hooks-library/core';
import { Route, Redirect, useLocation } from 'react-router-dom';
import AppLayout from 'containers/AppLayout';
import { RoleContext } from 'contexts';
import { notification } from 'antd';
import { PAGE_PATH_NOT_FOUND, UNAUTHORIZED_ACCESS } from 'utils/constants';

function PrivateRoute({ component: Component, ...rest }) {
  const [authToken] = useLocalStorage('authToken', localStorage.getItem('authToken'));
  const location = useLocation();

  const { isAuthorize } = React.useContext(RoleContext);

  if (authToken) {
    if (isAuthorize(rest.path)) {
      return (
        <AppLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </AppLayout>
      );
    }

    notification.error({ message: UNAUTHORIZED_ACCESS });

    return <Redirect to={PAGE_PATH_NOT_FOUND} />;
  }

  return (
    <Redirect to={{ pathname: '/login', state: { from: location }, search: 'error=sessao' }} />
  );
}

export default PrivateRoute;
