import React from 'react';
import { useLocalStorage } from '@react-hooks-library/core';
import { Route, Redirect } from 'react-router-dom';

function AuthRoute({ component: Component, ...rest }) {
  const [authToken] = useLocalStorage('authToken', localStorage.getItem('authToken'));

  if (!authToken) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return <Redirect to="/cockpit/homepage" />;
}

export default AuthRoute;
