import { lazy } from 'react';
import {
  PAGE_PATH_LOGIN,
  PAGE_PATH_NOT_FOUND,
  PAGE_PATH_HOMEPAGE,
  PAGE_PATH_SERVICES,
  PAGE_PATH_SERVICE_LIVE_CHAT,
  PAGE_PATH_UNHANDLED,
  PAGE_PATH_AUDIT,
  PAGE_PATH_PROATIVE_MESSAGE,
  PAGE_PATH_CURATION,
  PAGE_PATH_ANALYTICS,
  PAGE_PATH_CONSUMPTION,
  PAGE_PATH_ADMINISTRATOR,
  PAGE_PATH_FAQ_CLOE
} from 'utils/constants';

const Login = lazy(() => import('pages/Login'));
const NotFound = lazy(() => import('components/NoDataFound'));
const Home = lazy(() => import('pages/Home'));
const Service = lazy(() => import('pages/atendimentos/atendimentos'));
const ServiceLiveChat = lazy(() => import('pages/atendimentos/atendimentos'));
const Unhandled = lazy(() => import('pages/Unhandled'));
const Audit = lazy(() => import('pages/Audit'));
const ProactiveMessage = lazy(() => import('pages/ProactiveMessage'));
const Analytics = lazy(() => import('pages/Analytics'));
const Curation = lazy(() => import('pages/Curatorship'));
const Consumption = lazy(() => import('pages/Consumption'));
const Administrator = lazy(() => import('pages/Administrator'));
const Faq = lazy(() => import('pages/Faq'));

const homePage = () => {
  if (process.env.REACT_APP_IS_CLOE === 'true') {
    return Faq;
  }

  return Home;
};

const faqCloeRouter = () => {
  if (process.env.REACT_APP_IS_CLOE === 'true') {
    return [{}];
  }

  return [
    {
      path: PAGE_PATH_FAQ_CLOE,
      component: Faq,
      exact: true,
      private: true
    }
  ];
};

export default [
  {
    path: PAGE_PATH_LOGIN,
    component: Login,
    exact: true,
    private: false
  },
  {
    path: PAGE_PATH_NOT_FOUND,
    component: NotFound,
    exact: true,
    private: false
  },
  {
    path: PAGE_PATH_HOMEPAGE,
    component: homePage(),
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_SERVICES,
    component: Service,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_SERVICE_LIVE_CHAT,
    component: ServiceLiveChat,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_UNHANDLED,
    component: Unhandled,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_AUDIT,
    component: Audit,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_PROATIVE_MESSAGE,
    component: ProactiveMessage,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_ANALYTICS,
    component: Analytics,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_CURATION,
    component: Curation,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_CONSUMPTION,
    component: Consumption,
    exact: true,
    private: true
  },
  {
    path: PAGE_PATH_ADMINISTRATOR,
    component: Administrator,
    exact: true,
    private: true
  },
  ...faqCloeRouter()
];
