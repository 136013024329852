import { getAttendanceListService } from 'api';

export const OBTER_TOTAL_ATENDIMENTOS = 'OBTER_TOTAL_ATENDIMENTOS';
export const OBTER_TOTAL_MENSAGENS = 'OBTER_TOTAL_MENSAGENS';
export const BUSCAR_DADOS_GRAFICO = 'BUSCAR_DADOS_GRAFICO';
export const BUSCAR_LISTA_ATENDIMENTOS = 'BUSCAR_LISTA_ATENDIMENTOS';
export const BUSCAR_UMA_ATENDIMENTO = 'BUSCAR_UMA_ATENDIMENTO';
export const UPDATE_MESSAGE_UNHANDLED_REASON = 'UPDATE_MESSAGE_UNHANDLED_REASON';
export const LIMPAR_LISTA_ATENDIMENTOS = 'LIMPAR_LISTA_ATENDIMENTOS';
export const LIVECHAT_INICIADO = 'LIVECHAT_INICIADO';
export const ENVIAR_MENSAGEM_LIVECHAT = 'ENVIAR_MENSAGEM_LIVECHAT';
export const ENCERRAR_LIVECHAT = 'ENCERRAR_LIVECHAT';
export const COMECA_LIVECHAT = 'COMECA_LIVECHAT';
export const SALVA_INTERVAL_ID = 'SALVA_INTERVAL_ID';
export const ADICIONA_MENSAGEM = 'ADICIONA_MENSAGEM';
export const ATUALIZA_STATUS_MENSAGEM_LIDA = 'ATUALIZAR_STATUS_MENSAGEM_LIDA';

export const updateMessageUnhandledReason = (contextIndex, messageIndex, data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE_UNHANDLED_REASON,
      contextIndex,
      messageIndex,
      data
    });
  };
};

export const getAttendanceList = (
  current,
  pageSize,
  sortField,
  sortOrder,
  status,
  feedback,
  reason,
  start,
  end,
  username
) => {
  return async (dispatch) => {
    const resp = await getAttendanceListService(
      current,
      pageSize,
      sortField,
      sortOrder,
      status,
      feedback,
      reason,
      start,
      end,
      username
    );
    dispatch({
      type: BUSCAR_LISTA_ATENDIMENTOS,
      dados: resp
    });
    return resp;
  };
};

export const limparListaAtendimentos = () => {
  return {
    type: LIMPAR_LISTA_ATENDIMENTOS
  };
};

export const adicionaMensagem = (msg) => {
  return (dispatch) => {
    dispatch({
      type: ADICIONA_MENSAGEM,
      mensagem: msg
    });
  };
};

export const atualizarStatusMensagemLida = (key) => {
  return {
    type: ATUALIZA_STATUS_MENSAGEM_LIDA,
    key
  };
};

export const salvaIntervalId = (intervalId) => {
  return {
    type: SALVA_INTERVAL_ID,
    interval_id: intervalId
  };
};

export const limparLiveChat = () => {
  return {
    type: ENCERRAR_LIVECHAT
  };
};
