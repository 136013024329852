import { fake } from 'helpers';
import { BEARER_TOKEN } from 'utils/constants';

const auth = (_, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      accessToken: BEARER_TOKEN
    })
  );
};

const logout = (_, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      accessToken: 'logged out'
    })
  );
};

const resetPassword = (_, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json({}));
};

const updatePassword = (_, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      accessToken: BEARER_TOKEN
    })
  );
};

const getUser = (_, res, ctx) => {
  const data = localStorage.getItem('GET_USERS_MOCK');
  const users = [
    {
      id: 'a8407593-b1ab-4fc7-903d-9e76a27c2c22',
      username: fake.email(),
      name: fake.first(),
      lastName: fake.last(),
      active: 1,
      attemptAmount: 0,
      roles: ['SUPER_ADM'],
      locked: false,
      expired: false,
      enabled: true
    },
    {
      id: '2f9df142-64ea-41ce-8343-4f74f6434411',
      username: fake.email(),
      name: fake.first(),
      lastName: fake.last(),
      active: 1,
      attemptAmount: 0,
      roles: ['SUPER_ADM'],
      locked: false,
      expired: false,
      enabled: true
    },
    {
      id: '2f9df142-64ea-41ce-8343-4f74f64skapoks',
      username: fake.email(),
      name: fake.first(),
      lastName: fake.last(),
      active: 1,
      attemptAmount: 0,
      roles: ['ADM'],
      locked: false,
      expired: false,
      enabled: true
    },
    {
      id: 'b562204e-3522-4bb7-afc6-bc5b1fd9b081',
      username: fake.email(),
      name: fake.first(),
      lastName: fake.last(),
      active: 1,
      attemptAmount: 0,
      roles: ['FIRST_ACCESS'],
      locked: false,
      expired: false,
      enabled: true
    }
  ];

  if (data) {
    users.push(JSON.parse(data));
  }

  return res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json({
      content: [
        {
          id: '1',
          name: 'bot-bot-bot',
          users
        }
      ],
      pageable: {
        sort: {
          sorted: true,
          unsorted: false,
          empty: false
        },
        pageNumber: 0,
        pageSize: 10,
        offset: 0,
        unpaged: false,
        paged: true
      },
      totalPages: 1,
      totalElements: 1,
      last: true,
      sort: {
        sorted: true,
        unsorted: false,
        empty: false
      },
      numberOfElements: 1,
      first: true,
      size: 10,
      number: 0,
      empty: false
    })
  );
};

const createUser = (req, res, ctx) => {
  const data = req.body;
  data.id = (Math.random() + 1).toString(36).substring(7);
  data.password = (Math.random() + 1).toString(36).substring(7);
  localStorage.setItem('GET_USERS_MOCK', JSON.stringify(data));
  return res(ctx.delay(2000), ctx.status(201), ctx.json({ data }));
};

export default {
  auth,
  resetPassword,
  updatePassword,
  getUser,
  createUser,
  logout
};
