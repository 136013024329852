import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import Routes from 'routes';
import { RoleProvider } from 'contexts';

function App() {
  return (
    <RoleProvider>
      <Routes />
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </RoleProvider>
  );
}

export default App;
