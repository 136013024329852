import { dayjs } from 'helpers';
import { DEFAULT_FORMAT_DATE } from 'utils/constants';

const dateStartEndReturnNow = (start, end) => ({
  startCustom: dayjs(start || dayjs().format(DEFAULT_FORMAT_DATE), DEFAULT_FORMAT_DATE).startOf(
    'day'
  ),
  endCustom: dayjs(end || dayjs().format(DEFAULT_FORMAT_DATE), DEFAULT_FORMAT_DATE).endOf('day')
});

const dateStartEndReturn = (start, end) => ({
  startCustom: dayjs(start || '01-01-1970', DEFAULT_FORMAT_DATE).startOf('day'),
  endCustom: dayjs(end || dayjs().format(DEFAULT_FORMAT_DATE), DEFAULT_FORMAT_DATE).endOf('day')
});

const dateStartEndSubtract = (start, end, format = DEFAULT_FORMAT_DATE) => {
  const startCustom = start || dayjs().subtract(1, 'year').format(format);
  const endCustom = end || dayjs().format(format);

  return {
    startCustom: dayjs(startCustom, format).startOf('day').valueOf(),
    endCustom: dayjs(endCustom, format).endOf('day').valueOf()
  };
};

const dateStartEndMonth = (start, end) => {
  const startCustom = start || dayjs().startOf('month');
  const endCustom = end || dayjs();

  return {
    startCustom: startCustom.startOf('day').valueOf(),
    endCustom: endCustom.endOf('day').valueOf()
  };
};

const dateStartEndYear = () => {
  const startCustom = dayjs().subtract(1, 'year').format(DEFAULT_FORMAT_DATE);
  const endCustom = dayjs().format(DEFAULT_FORMAT_DATE);

  return {
    startCustom: startCustom.startOf('day').valueOf(),
    endCustom: endCustom.endOf('day').valueOf()
  };
};

export {
  dateStartEndReturnNow,
  dateStartEndReturn,
  dateStartEndSubtract,
  dateStartEndMonth,
  dateStartEndYear
};
