import { conversation } from 'services';
import { API_CONVERSATION_PROACTIVE_MESSAGE } from 'utils/constants';

const getProactiveMessageSearchAll = ({ page, size, sort }) => {
  return conversation.get(API_CONVERSATION_PROACTIVE_MESSAGE, {
    params: {
      page,
      size,
      sort
    }
  });
};

const destroyProactiveMessage = (id) => {
  return conversation.delete(`${API_CONVERSATION_PROACTIVE_MESSAGE}/${id}`);
};

const createProactiveMessage = (body) => {
  return conversation.post(API_CONVERSATION_PROACTIVE_MESSAGE, body);
};

export { getProactiveMessageSearchAll, destroyProactiveMessage, createProactiveMessage };
