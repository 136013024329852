import axios from 'axios';
import { notification } from 'antd';

const onRequest = (config) => {
  const token = localStorage.getItem('authToken') || '';

  if (token) {
    return {
      ...config,
      headers: {
        authorization: token
      }
    };
  }

  return config;
};

const onRequestError = (error) => Promise.reject(error);

const onResponse = (response) => response;

const messageErrorAixos = (error) => {
  if (error && error.response && error.response.data) {
    if (error.response.data.message) {
      return error.response.data.message;
    }

    if (Object.is(error.response.data)) {
      return JSON.stringify(error.response.data);
    }
  }

  return error.toString();
};

const onResponseError = (error) => {
  if (axios.isAxiosError(error) && process.env.NODE_ENV !== 'production') {
    notification.error({
      message: messageErrorAixos(error),
      description: 'Verifique a conexão com o servidor',
      duration: 3
    });
  }

  if (
    (!error.response || [403, 401].includes(error.response.status)) &&
    process.env.NODE_ENV !== 'test'
  ) {
    localStorage.clear();
    window.location.href = '/';
    window.location.reload();
  }

  return Promise.reject(error);
};

export { onRequest, onRequestError, onResponse, onResponseError };
