import { dayjs, dateStartEndReturn, dateStartEndMonth, dateStartEndYear } from 'helpers';
import { DEFAULT_FORMAT_DATE, API_CONVERSATION_REPORTS } from 'utils/constants';
import { conversation } from 'services';

const getReports = (params) => {
  return conversation.get(API_CONVERSATION_REPORTS, { params });
};

const getTotalAttendancesService = () => {
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: dayjs().startOf('day').valueOf(),
      end: dayjs().endOf('day').valueOf(),
      type: 'contexts'
    }
  });
};

const getTotalMessagesService = () => {
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: dayjs().startOf('day').valueOf(),
      end: dayjs().endOf('day').valueOf(),
      type: 'messages'
    }
  });
};

const getCsatData = (start, end) => {
  const { startCustom, endCustom } = dateStartEndReturn(start, end);
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: startCustom.valueOf(),
      end: endCustom.valueOf(),
      type: 'feedbacks'
    }
  });
};

const getDifferentUsersPerDay = (start, end) => {
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start,
      end,
      type: 'totalUsersByDate'
    }
  });
};

const getAttendancesByDay = (start, end) => {
  const { endCustom, startCustom } = dateStartEndMonth(start, end);
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: startCustom,
      end: endCustom,
      type: 'totalContextsByDate'
    }
  });
};

const getAttendanceByHour = (date) => {
  const dateCustom = dayjs(date, DEFAULT_FORMAT_DATE);
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: dateCustom.startOf('day').valueOf(),
      end: dateCustom.endOf('day').valueOf(),
      type: 'totalContextsByHour'
    }
  });
};

const getMessagePerHour = (date) => {
  const dateCustom = dayjs(date, DEFAULT_FORMAT_DATE);
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: dateCustom.startOf('day').valueOf(),
      end: dateCustom.endOf('day').valueOf(),
      type: 'totalMessagesByHour'
    }
  });
};

const getMessagePerDay = (start, end) => {
  const { endCustom, startCustom } = dateStartEndMonth(start, end);
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: startCustom,
      end: endCustom,
      type: 'totalMessagesByDate'
    }
  });
};

const getWordCloud = () => {
  return conversation.get(API_CONVERSATION_REPORTS, {
    start: 0,
    end: 0,
    type: 'wordCloud'
  });
};

const getAnnualData = () => {
  const { startCustom, endCustom } = dateStartEndYear();
  return conversation.get(API_CONVERSATION_REPORTS, {
    params: {
      start: startCustom,
      end: endCustom,
      type: 'feedbacksByMonth'
    }
  });
};

export {
  getAnnualData,
  getTotalAttendancesService,
  getWordCloud,
  getMessagePerDay,
  getMessagePerHour,
  getTotalMessagesService,
  getCsatData,
  getAttendanceByHour,
  getDifferentUsersPerDay,
  getAttendancesByDay,
  getReports
};
