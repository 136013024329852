import { combineReducers } from 'redux';

const adicionarMessageConentReducer = (mensagens = [], action) => {
  if (action.type === 'ADICIONAR_MENSAGEM_CONTENT') {
    return action.payload;
  }
  return mensagens;
};

const adicionarOpcaoUnhandledReducer = (opcoes = [], action) => {
  if (action.type === 'ADICIONAR_OPCOAO_UNHANDLED') {
    return action.payload;
  }
  return opcoes;
};

const Unhandle = combineReducers({
  adicionarMessageConentReducer,
  adicionarOpcaoUnhandledReducer
});

export default Unhandle;
