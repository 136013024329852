import { combineReducers } from 'redux';
import Cockpit from './cockpit';
import Atendimento from './atendimento';
import Unhandled from './unhandled';
import SocketReducer from './socket';

const appReducer = combineReducers({
  cockpit: Cockpit,
  atendimento: Atendimento,
  unhandled: Unhandled,
  socket: SocketReducer
});

export default appReducer;
