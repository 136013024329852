import styled from 'styled-components';
import { Avatar as AvatarCmp } from 'antd';

export const Header = styled.header`
  width: 100vw;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12);
  min-height: 64px;
  display: flex;
  padding: 0 40px;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const ContainerLayout = styled.main`
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const Content = styled.div`
  overflow: auto;
  max-height: calc(100vh - 64px);
`;

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 100vh;
`;

export const Avatar = styled(AvatarCmp)``;
