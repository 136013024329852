import React from 'react';
import Loading from 'components/Loading';
import { Container } from './styles';

function AppLoading() {
  return (
    <Container>
      <Loading />
    </Container>
  );
}

export default AppLoading;
