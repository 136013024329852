const create = (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json({
      id: '1',
      name: 'bot-teste',
      dnsPrefix: 'hitss@123',
      teamsCredentials: {},
      whatsappCredentials: {},
      watsonCredentials: {},
      qnaCredentials: {}
    })
  );
};

export default {
  create
};
