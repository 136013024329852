import React from 'react';
import {
  ControlOutlined,
  EyeOutlined,
  HomeOutlined,
  LineChartOutlined,
  MessageOutlined,
  DollarOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  PAGE_PATH_HOMEPAGE,
  PAGE_PATH_ANALYTICS,
  PAGE_PATH_CONSUMPTION,
  PAGE_PATH_CURATION,
  PAGE_PATH_AUDIT,
  PAGE_PATH_PROATIVE_MESSAGE,
  PAGE_PATH_SERVICE_LIVE_CHAT,
  PAGE_PATH_ADMINISTRATOR
} from 'utils/constants';

const homePageRoute = {
  path: PAGE_PATH_HOMEPAGE,
  key: 'homepage',
  icon: <HomeOutlined />,
  text: 'Home',
  visible: true
};

const administratorPageRoute = {
  path: PAGE_PATH_ADMINISTRATOR,
  key: 'administrador',
  icon: <UserOutlined />,
  text: 'Administrador',
  visible: true
};

export const ROUTE_MENU_LIST =
  process.env.REACT_APP_IS_CLOE === 'true'
    ? [homePageRoute, administratorPageRoute]
    : [
        homePageRoute,
        {
          path: PAGE_PATH_ANALYTICS,
          key: 'analytics',
          icon: <LineChartOutlined />,
          text: 'Analytics',
          visible: true
        },
        {
          path: PAGE_PATH_CONSUMPTION,
          key: 'consumo',
          icon: <DollarOutlined />,
          text: 'Consumo',
          visible: true
        },
        {
          path: PAGE_PATH_CURATION,
          key: 'curadoria',
          icon: <ControlOutlined />,
          text: 'Curadoria',
          visible: true
        },
        {
          path: PAGE_PATH_AUDIT,
          key: 'auditoria',
          icon: <EyeOutlined />,
          text: 'Auditoria',
          visible: true
        },
        {
          path: PAGE_PATH_PROATIVE_MESSAGE,
          key: 'mensagem-proativa',
          icon: <MessageOutlined />,
          text: 'Mensagem Proativa',
          visible: true
        },
        {
          path: PAGE_PATH_SERVICE_LIVE_CHAT,
          key: 'atendimento',
          icon: <MessageOutlined />,
          text: 'Atendimento',
          visible: !window._env_.LIVECHAT_DISABLED
        },
        administratorPageRoute
      ];
