import React from 'react';
import * as jwtDecode from 'jwt-decode';
import { fake } from 'helpers';
import { ROLE_SUPER_ADMIN, ROLE_INVALID } from 'utils/constants';
import { ROLES_PAGE_ACCESS } from './utils';

export const RoleContext = React.createContext({
  getUser: () => {},
  getRoles: () => {},
  user: () => {},
  getToken: [],
  isAuthorize: false
});

export function RoleProvider({ children }) {
  const [user, setUser] = React.useState();
  const [getToken, setGetToken] = React.useState(localStorage.getItem('authToken') || '');

  const getUser = React.useCallback(() => {
    if (process.env.REACT_APP_MOCK_ENABLE === 'true') {
      return setUser(`${fake.first()} ${fake.last()}`);
    }

    if (getToken) {
      return setUser(jwtDecode(getToken).sub);
    }

    return setUser(ROLE_INVALID);
  }, [getToken]);

  const getRoles = React.useCallback(() => {
    if (process.env.REACT_APP_MOCK_ENABLE === 'true') {
      return [ROLE_SUPER_ADMIN];
    }

    if (getToken) {
      return jwtDecode(getToken).auth;
    }

    return [ROLE_INVALID];
  }, [getToken]);

  const isAuthorize = React.useCallback(
    (path) => {
      return !!(
        ROLES_PAGE_ACCESS[path] &&
        ROLES_PAGE_ACCESS[path].filter((value) => getRoles().includes(value)).length
      );
    },
    [getRoles]
  );

  React.useEffect(() => {
    if (!getToken && localStorage.getItem('authToken')) {
      setGetToken(localStorage.getItem('authToken'));
      getRoles();
      getUser();
    }
  }, [getRoles, getToken, getUser]);

  const state = React.useMemo(
    () => ({
      getUser,
      getRoles,
      user,
      getToken: [getToken, setGetToken],
      isAuthorize
    }),
    [getRoles, getToken, getUser, isAuthorize, user]
  );

  return <RoleContext.Provider value={state}>{children}</RoleContext.Provider>;
}
