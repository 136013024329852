import {
  PAGE_PATH_LOGIN,
  PAGE_PATH_NOT_FOUND,
  PAGE_PATH_HOMEPAGE,
  PAGE_PATH_SERVICES,
  PAGE_PATH_SERVICE_LIVE_CHAT,
  PAGE_PATH_UNHANDLED,
  PAGE_PATH_AUDIT,
  PAGE_PATH_PROATIVE_MESSAGE,
  PAGE_PATH_CURATION,
  PAGE_PATH_ANALYTICS,
  PAGE_PATH_CONSUMPTION,
  PAGE_PATH_ADMINISTRATOR,
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_FIRST_ACCESS,
  ROLE_MANAGER,
  ROLE_CURATOR,
  ROLE_ATTENDANT,
  ROLE_USER_BROADCAST,
  ROLE_USER_MULTICAST,
  ROLE_USER_UNICAST,
  PAGE_PATH_FAQ_CLOE
} from 'utils/constants';

export const ROLES_PAGE_ACCESS = {
  [PAGE_PATH_LOGIN]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_FIRST_ACCESS,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_FAQ_CLOE]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_FIRST_ACCESS,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_NOT_FOUND]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_FIRST_ACCESS,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_SERVICES]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_SERVICE_LIVE_CHAT]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_UNHANDLED]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_PROATIVE_MESSAGE]: [
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_CURATOR,
    ROLE_ATTENDANT,
    ROLE_USER_BROADCAST,
    ROLE_USER_MULTICAST,
    ROLE_USER_UNICAST
  ],
  [PAGE_PATH_HOMEPAGE]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER, ROLE_CURATOR],
  [PAGE_PATH_ANALYTICS]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER, ROLE_CURATOR],
  [PAGE_PATH_CURATION]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER, ROLE_CURATOR],
  [PAGE_PATH_ADMINISTRATOR]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER],
  [PAGE_PATH_CONSUMPTION]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER],
  [PAGE_PATH_AUDIT]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER, ROLE_CURATOR]
};
