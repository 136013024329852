/* eslint-disable quotes */
import { rest } from 'msw';
import mockAuth from './auth';
import mockBots from './bots';
import mockConvertion from './convertion';

export default [
  // AUTENTICACAO
  rest.post('http://localhost:8080/auth/signin', mockAuth.auth),
  rest.put('http://localhost:8080/auth/signin/updatePassword', mockAuth.resetPassword),
  rest.get('http://localhost:8080/users', mockAuth.getUser),
  rest.post('http://localhost:8080/auth/signout', mockAuth.logout),
  rest.post('http://localhost:8080/users', mockAuth.createUser),

  // BOTS
  rest.post('http://localhost:8085/bots', mockBots.create),

  // CONVERSATION
  rest.get('http://localhost:8080/conversation/reports', mockConvertion.graph),
  rest.get('http://localhost:8080/conversation/proactive-message', mockConvertion.proactiveMessage),
  rest.get('http://localhost:8080/conversation/unhandledReasons', mockConvertion.unhandledReasons),
  rest.get('http://localhost:8080/conversation/feedbacks', mockConvertion.feedbacks),
  rest.get(
    'http://localhost:8080/conversation/contexts/distinctUsers',
    mockConvertion.disctinctUser
  ),
  rest.get('http://localhost:8080/conversation/reasons', mockConvertion.reasons),
  rest.delete(
    'http://localhost:8080/conversation/proactive-message/*',
    mockConvertion.destroyProactiveMessage
  ),
  rest.post(
    'http://localhost:8080/conversation/proactive-message',
    mockConvertion.createProactiveMessage
  ),
  rest.get('http://localhost:8080/conversation/status', mockConvertion.status),
  rest.get('http://localhost:8080/conversation/messages', mockConvertion.messages),
  rest.get('http://localhost:8080/conversation/contexts', mockConvertion.contextsTable)
];
