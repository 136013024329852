import React from 'react';
import RobotAvatar from 'assets/images/robot-avatar.png';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import { Link } from 'react-router-dom';
import SideMenu from './SideMenu';
import { Header, ContainerLayout, Content, Container, Avatar } from './styles';

function AppLayout({ children }) {
  return (
    <Container>
      <Header>
        <Link to="/">
          <Logo height={48} />
        </Link>
        <Avatar size={32} src={RobotAvatar} />
      </Header>
      <ContainerLayout>
        <SideMenu />
        <Content>{children}</Content>
      </ContainerLayout>
    </Container>
  );
}

AppLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default AppLayout;
