export const DEFAULT_FORMAT_DATE = 'DD-MM-YYYY';
export const DEFAULT_FORMAT_DATE_INPUT = 'DD/MM/YYYY';
export const ERROR_MESSAGE_REMOVE = 'Erro ao remover a mensagem';
export const CONNECT_SOCKET = 'conectando-socket';
export const BEARER_TOKEN =
  'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aWN0b3IxIiwiYXV0aCI6WyJST0xFX0FETSJdLCJib3QiOiIxIiwiaWF0IjoxNjQ4MjU2ODMzLCJleHAiOjE2NDgzNDMyMzN9.QC7awg-cugd_FDg98PWsCpX-u7SO2I1DX5pZ4y3PMD4';

// MESSAGE
export const UNAUTHORIZED_ACCESS = 'Acesso não autorizado.';

// ROLES
export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const ROLE_ADMIN = 'ROLE_ADM';
export const ROLE_FIRST_ACCESS = 'FIRST_ACCESS';
export const ROLE_MANAGER = 'MANAGER';
export const ROLE_CURATOR = 'CURATOR';
export const ROLE_ATTENDANT = 'ATTENDANT';
export const ROLE_USER_BROADCAST = 'USER_BROADCAST';
export const ROLE_USER_MULTICAST = 'USER_MULTICAST';
export const ROLE_USER_UNICAST = 'USER_UNICAST';
export const ROLE_INVALID = 'INVALID';

// PAGE PATH
export const PAGE_PATH_LOGIN = '/login';
export const PAGE_PATH_NOT_FOUND = '/404';
export const PAGE_PATH_HOMEPAGE = '/cockpit/homepage';
export const PAGE_PATH_SERVICES = '/cockpit/atendimentos';
export const PAGE_PATH_SERVICE_LIVE_CHAT = '/cockpit/atendimento';
export const PAGE_PATH_UNHANDLED = '/cockpit/unhandled';
export const PAGE_PATH_AUDIT = '/cockpit/auditoria';
export const PAGE_PATH_PROATIVE_MESSAGE = '/cockpit/mensagem-proativa';
export const PAGE_PATH_ANALYTICS = '/cockpit/analytics';
export const PAGE_PATH_CURATION = '/cockpit/curadoria';
export const PAGE_PATH_CONSUMPTION = '/cockpit/consumo';
export const PAGE_PATH_ADMINISTRATOR = '/cockpit/administrador';
export const PAGE_PATH_FAQ_CLOE = '/cockpit/cloe/faq';

// PATH REQUEST
export const API_CONVERSATION_AUTH = '/auth';
export const API_CONVERSATION_CONTEXTS = '/conversation/contexts';
export const API_CONVERSATION_FEEDBACKS = '/conversation/feedbacks';
export const API_CONVERSATION_INTENTS = '/conversation/intents';
export const API_CONVERSATION_MESSAGES = '/conversation/messages';
export const API_CONVERSATION_PROACTIVE_MESSAGE = '/conversation/proactive-message';
export const API_CONVERSATION_REASONS = '/conversation/reasons';
export const API_CONVERSATION_REPORTS = '/conversation/reports';
export const API_CONVERSATION_STATUS = '/conversation/status';
export const API_CONVERSATION_UNHANDLED_REASONS = '/conversation/unhandledReasons';
export const API_CONVERSATION_USER = '/conversation/users';
