import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AppLoading from 'containers/AppLoading';
import { PAGE_PATH_HOMEPAGE, PAGE_PATH_LOGIN } from 'utils/constants';
import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import routeList from './utils';

function Routes() {
  const history = createBrowserHistory({ basename: window._env_.BASENAME });

  return (
    <Router history={history}>
      <React.Suspense fallback={<AppLoading />}>
        <Switch>
          {routeList.map((route) => {
            if (route.private) {
              return (
                <PrivateRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            }

            return (
              <AuthRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
          <Route
            path="*"
            component={
              localStorage.getItem('authToken') ? (
                <Redirect to={PAGE_PATH_HOMEPAGE} />
              ) : (
                <Redirect to={PAGE_PATH_LOGIN} />
              )
            }
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default Routes;
