import axios from 'axios';
import { onRequest, onRequestError, onResponse, onResponseError } from 'services/utils';

const api = axios.create({
  baseURL: window._env_?.CONVERSATION_URL || process.env.REACT_APP_CONVERSATION_URL
});

api.interceptors.request.use(onRequest, onRequestError);

api.interceptors.response.use(onResponse, onResponseError);

export default api;
