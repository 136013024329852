import { maskJs } from 'mask-js';
import { parse } from 'json2csv';
import dayjs from '../dayjs';

export const checkStringIsValue = (value) => {
  if (value) {
    return value;
  }

  return '';
};

export const exportService = (atendimentos, dataInicial, dataFinal) => {
  const csvContent = 'data:text/csv;charset=utf-8-,%EF%BB%BF';
  const header = [
    'Data da Atendimento',
    'Número',
    'CNPJ',
    'Nome do Contato',
    'Razão Social',
    'E-mail',
    'Atendimento Finalizado',
    'Nota de Feedback',
    'Sugestão do Cliente'
  ];
  let csvFile = '\ufeff'.concat(header.join(';')).concat('\n');
  (atendimentos || []).forEach((atendimento) => {
    const yesAndNotString = atendimento.finalizada ? 'Sim' : 'Não';
    const numero = checkStringIsValue(atendimento.telefoneCliente);
    const cnpj = checkStringIsValue(maskJs('99.999.999/9999-99', atendimento.cnpj));
    const nomeContato = checkStringIsValue(atendimento.nomeContato);
    const razaoSocial = checkStringIsValue(atendimento.razaoSocial);
    const email = atendimento.emailCliente;
    const atendimentoFinalizada = atendimento.finalizada ? yesAndNotString : '';
    const dataAtendimento = atendimento.dataAtendimento
      ? dayjs(atendimento.dataAtendimento).format('L LT')
      : '';
    const feedback = checkStringIsValue(atendimento.feedback);
    const sugestao = checkStringIsValue(atendimento.sugestao);
    csvFile = csvFile.concat(
      `${dataAtendimento};${numero};${cnpj};${nomeContato};${razaoSocial};${email};${atendimentoFinalizada};${feedback};${sugestao}\n`
    );
  });
  const toExport = new Blob([csvFile], { type: csvContent });
  const oldData = document.getElementById('atendimentosExportData');
  if (oldData) document.body.removeChild(oldData);
  const a = document.createElement('a');
  a.id = 'atendimentosExportData';
  a.href = URL.createObjectURL(toExport);
  a.download = `Atendimentos_${dataInicial}_${dataFinal}.csv`;
  document.body.appendChild(a);
  a.click();
};

export const downloadReport = (data, file) => {
  const dataTransform = (month) => ({ ...month, date: dayjs(month.date).format('MMM-YYYY') });

  const csvContent = 'data:text/csv;charset=utf-8,%EF%BB%BF';
  const fields = [
    {
      label: 'Mês',
      value: 'date'
    },
    {
      label: 'Satisfeito',
      value: 'satisfeito'
    },
    {
      label: 'Insatisfeito',
      value: 'insatisfeito'
    },
    {
      label: 'Neutro',
      value: 'neutro'
    }
  ];
  const universalBOM = '\uFEFF';
  const csvFile =
    universalBOM + parse(data, { fields, delimiter: ';', transforms: [dataTransform] });
  const toExport = new Blob([csvFile], { type: csvContent });
  const oldData = document.getElementById('csatExportData');
  if (oldData) {
    document.body.removeChild(oldData);
  }
  const hyperlinkElement = document.createElement('a');
  hyperlinkElement.id = 'csatExportData';
  hyperlinkElement.href = URL.createObjectURL(toExport);
  hyperlinkElement.download = file;
  document.body.appendChild(hyperlinkElement);
  hyperlinkElement.click();
};
