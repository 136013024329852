import styled from 'styled-components';
import { Layout, Menu } from 'antd';

export const Sider = styled(Layout.Sider)`
  max-height: calc(100vh - 64px);
  border-right: 0.1px solid lightgray;

  & > .ant-layout-sider-children {
    & > .ant-menu-inline {
      & > .ant-menu-item {
        &:last-child {
          padding-left: 24px !important;
        }
      }
    }
  }
`;

export const MenuLogout = styled(Menu.Item)``;
