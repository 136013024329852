import {
  ADICIONA_MENSAGEM,
  OBTER_TOTAL_ATENDIMENTOS,
  OBTER_TOTAL_MENSAGENS,
  BUSCAR_DADOS_GRAFICO,
  BUSCAR_LISTA_ATENDIMENTOS,
  BUSCAR_UMA_ATENDIMENTO,
  COMECA_LIVECHAT,
  ENCERRAR_LIVECHAT,
  LIMPAR_LISTA_ATENDIMENTOS,
  LIVECHAT_INICIADO,
  SALVA_INTERVAL_ID,
  ATUALIZA_STATUS_MENSAGEM_LIDA,
  UPDATE_MESSAGE_UNHANDLED_REASON
} from '../actions/cockpit_actions';

export const INITIAL_STATE = {
  dashboard_data: {
    totalAtendimentos: '',
    totalMensagens: '',
    mediaFeedback: '',
    totalFinalizada: '',
    totalNaoFinalizada: ''
  },
  grafico_data: null,
  atendimento_data: {},
  pagination_config: {},
  atendimento_selecionada: {},
  livechat: { iniciou: false },
  interval_id: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case OBTER_TOTAL_ATENDIMENTOS:
      return { ...state, total_atendimentos: action.dados };
    case OBTER_TOTAL_MENSAGENS:
      return { ...state, total_mensagens: action.dados };
    case BUSCAR_DADOS_GRAFICO:
      return { ...state, grafico_data: action.dados };
    case BUSCAR_LISTA_ATENDIMENTOS:
      const { dados } = action;

      const paginationConfig = {
        total: dados.totalElements,
        pageSize: dados.size,
        current: dados.number + 1,
        hideOnSinglePage: true
      };

      return {
        ...state,
        conversa_data: action.dados,
        pagination_config: paginationConfig
      };
    case UPDATE_MESSAGE_UNHANDLED_REASON:
      const { atendimento_data: atendimentoData } = state;
      atendimentoData.content[action.contextIndex].messages[action.messageIndex] = action.data;
      return { ...state, atendimento_data: atendimentoData };
    case BUSCAR_UMA_ATENDIMENTO:
      return { ...state, atendimento_selecionada: action.dados };

    case LIVECHAT_INICIADO:
      return { ...state, livechat: action.livechat };

    case SALVA_INTERVAL_ID:
      return { ...state, interval_id: action.interval_id };

    case LIMPAR_LISTA_ATENDIMENTOS:
      return { ...state, atendimento_data: {}, atendimento_selecionada: {} };

    case COMECA_LIVECHAT:
      return { ...state, livechat: { iniciou: true, atendimentoId: action.atendimentoId } };

    case ENCERRAR_LIVECHAT:
      return { ...state, livechat: {} };
    case ADICIONA_MENSAGEM:
      if (state.atendimento_selecionada)
        (state.atendimento_selecionada.mensagens || []).push(action.mensagem);
      return { ...state, atendimento_selecionada: state.atendimento_selecionada };

    case ATUALIZA_STATUS_MENSAGEM_LIDA:
      const stateCustom = state;
      stateCustom.conversa_data.content[action.key].novaMensagemRecebida = false;
      return { ...state, conversa_data: state.conversa_data };

    default:
      return state;
  }
}
