import { conversation } from 'services';
import { API_CONVERSATION_USER } from 'utils/constants';

const getUsersAll = (page, size, sort) => {
  return conversation.get(API_CONVERSATION_USER, {
    params: {
      'bot-id': window._env_.BOT_ID || process.env.REACT_APP_BOT_ID,
      page,
      size,
      sort
    }
  });
};

const postCreateUser = (data) => {
  return conversation.post(API_CONVERSATION_USER, data, {
    params: { 'bot-id': window._env_.BOT_ID }
  });
};

export { getUsersAll, postCreateUser };
