import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'antd/dist/antd.css';
import ptBR from 'antd/es/locale/pt_BR';
import 'dayjs/locale/pt-br';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'utils/Theme';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { dayjs } from 'helpers';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyle from 'utils/GlobalStyle';
import App from './App';
import './recursos/css/cockpit.css';
import './recursos/css/custom_fonts.css';
import './recursos/css/footer.css';
import './recursos/css/general_custom_styles.css';
import './recursos/css/material-shadows.css';
import './recursos/css/style.css';
import { persistor, store } from './store';

dayjs.locale('pt-br');
dayjs.extend(customParseFormat);

if (process.env.REACT_APP_MOCK_ENABLE === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');

  worker.start({
    onUnhandledRequest: 'bypass'
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://258f172c5a58469eb198880a673435c9@o1269912.ingest.sentry.io/6460311',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    maxBreadcrumbs: 50,
    debug: true
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 600000
    }
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConfigProvider locale={ptBR}>
          <PersistGate loading="null" persistor={persistor}>
            <GlobalStyle />
            <App />
          </PersistGate>
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
