import React from 'react';
import { Menu, Popconfirm, message, notification } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { RoleContext } from 'contexts';
import { LogoutOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { postLogout } from 'api';
import { ROUTE_MENU_LIST } from './utils';
import { Sider, MenuLogout } from './styles';

function SideMenu() {
  const { isAuthorize } = React.useContext(RoleContext);

  const [collapsed, setCollapsed] = React.useState(true);
  const location = useLocation();

  const paths = location.pathname.split('/');
  const selected = paths[paths.length - 1];

  const [visible, setVisible] = React.useState(false);

  const { isLoading, mutate, isError, remove } = useMutation(postLogout, {
    onSuccess: () => {
      setVisible(false);
      localStorage.clear();
      message.success('Usuário foi deslogado com sucesso.');
      window.location.reload();
    }
  });

  const onConfirm = async () => {
    mutate();
  };

  React.useEffect(() => {
    if (isError) {
      notification.error({ message: 'Error durante o logout. Favor tente novamente.' });
      remove();
    }
  }, [isError, remove]);

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}>
      <Menu theme="light" mode="inline" selectedKeys={[selected]}>
        {ROUTE_MENU_LIST.map((item) => {
          if (isAuthorize(item.path) && item.visible) {
            return (
              <Menu.Item title={item.text} key={item.key} icon={item.icon}>
                <Link to={item.path}>
                  <span>{item.text}</span>
                </Link>
              </Menu.Item>
            );
          }

          return undefined;
        })}
        <Popconfirm
          title="Tem certeza que deseja sair?"
          placement="right"
          visible={visible}
          onConfirm={onConfirm}
          okButtonProps={{ loading: isLoading }}
          onCancel={() => setVisible(false)}>
          <MenuLogout
            title="Sair"
            key="logout"
            icon={<LogoutOutlined />}
            active={collapsed}
            onClick={() => setVisible(true)}>
            <span>Sair</span>
          </MenuLogout>
        </Popconfirm>
      </Menu>
    </Sider>
  );
}

export default SideMenu;
