import { combineReducers } from 'redux';
import uuid from 'uuid/v1';

// Fila de espera
const filaEsperaReducer = (filaEspera = [], action) => {
  if (action.type === 'ADICIONAR_FILA_ESPERA') {
    return action.payload;
  }
  if (action.type === 'REMOVER_FILA_ESPERA') {
    return filaEspera.filter((fila) => {
      return fila.id !== action.payload;
    });
  }
  return filaEspera;
};

// Fila de atendimento
const filaAtendimentoReducer = (filaAtendimento = [], action) => {
  let indexDaConversa;
  const filaAtendimentoCustom = filaAtendimento;

  switch (action.type) {
    case 'ADICIONAR_FILA_ATENDIMENTO':
      if (
        filaAtendimentoCustom.findIndex((atendimento) => {
          return atendimento.id === action.payload.id;
        }) !== -1
      )
        return filaAtendimentoCustom;
      return [...filaAtendimentoCustom, action.payload];

    case 'REMOVER_FILA_ATENDIMENTO':
      return filaAtendimentoCustom.filter((fila) => {
        return fila.id !== action.payload;
      });

    case 'ADICIONAR_MENSAGEM':
      indexDaConversa = filaAtendimentoCustom.findIndex((conversation) => {
        return conversation.id === action.payload.id;
      });
      if (filaAtendimentoCustom[indexDaConversa]) {
        filaAtendimentoCustom[indexDaConversa].mensagens.push(action.payload.mensagem);
        if (action.payload.id !== filaAtendimentoCustom[indexDaConversa].id) {
          filaAtendimentoCustom[indexDaConversa].novas += 1;
        } else {
          filaAtendimentoCustom[indexDaConversa].novas = 0;
        }
      }

      return [...filaAtendimentoCustom];

    case 'ADICIONA_LISTA_DE_MENSAGEM':
      indexDaConversa = filaAtendimentoCustom.findIndex((conversation) => {
        return conversation.id === action.payload.id;
      });
      if (filaAtendimentoCustom[indexDaConversa]) {
        let index = filaAtendimentoCustom[indexDaConversa].mensagensNovas.length;
        let novas = 0;
        let ultimaMensagem = 0;
        for (index; index < action.payload.mensagens.length; index += 1) {
          const novaMensagem = action.payload.mensagens[index];
          ultimaMensagem = action.payload.mensagens[index].timestamp;
          if (!novaMensagem.agent) {
            novas += 1;
          }
          filaAtendimentoCustom[indexDaConversa].mensagensNovas.push({
            ...novaMensagem,
            id: uuid()
          });
        }
        filaAtendimentoCustom[indexDaConversa].mensagens = [
          ...filaAtendimentoCustom[indexDaConversa].mensagensAntigas,
          ...filaAtendimentoCustom[indexDaConversa].mensagensNovas
        ];
        filaAtendimentoCustom[indexDaConversa].novas = novas;
        filaAtendimentoCustom[indexDaConversa].ultimaMensagem = ultimaMensagem;
      }

      return [...filaAtendimentoCustom];

    case 'MENSAGENS_ANTIGAS':
      const mensagensAntigas = action.payload.mensagens;
      indexDaConversa = filaAtendimentoCustom.findIndex((conversation) => {
        return conversation.id === action.payload.conversationId;
      });
      filaAtendimentoCustom[indexDaConversa].mensagensAntigas = [...mensagensAntigas];
      filaAtendimentoCustom[indexDaConversa].mensagens = [
        ...filaAtendimentoCustom[indexDaConversa].mensagensAntigas,
        ...filaAtendimentoCustom[indexDaConversa].mensagensNovas
      ];

      return [...filaAtendimentoCustom];

    case 'ZERA_NOVAS':
      indexDaConversa = filaAtendimentoCustom.findIndex((conversation) => {
        return conversation.id === action.payload;
      });
      filaAtendimentoCustom[indexDaConversa].novas = 0;
      return [...filaAtendimentoCustom];

    case 'ATUALIZA_TEXTO_DIGITADO':
      indexDaConversa = filaAtendimentoCustom.findIndex((conv) => {
        return conv.id === action.payload.id;
      });
      filaAtendimentoCustom[indexDaConversa] = {
        ...filaAtendimentoCustom[indexDaConversa],
        typedText: action.payload.typedText
      };
      return [...filaAtendimentoCustom];

    default:
      return filaAtendimentoCustom;
  }
};

// Ações do chat
const conversaSelecionadaReducer = (conversaSelecionada = null, action) => {
  if (action.type === 'SELECIONAR_CONVERSA') {
    return action.payload;
  }
  return conversaSelecionada;
};

const Atendimento = combineReducers({
  filaEsperaReducer,
  filaAtendimentoReducer,
  conversaSelecionadaReducer
});

export default Atendimento;
