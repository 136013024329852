import { conversation } from 'services';
import { API_CONVERSATION_AUTH } from 'utils/constants';

const postAuthLogin = async (data) => {
  const response = await conversation.post(`${API_CONVERSATION_AUTH}/signin`, data);
  localStorage.setItem('authToken', response.data.accessToken);
  return response;
};

const postLogout = () => {
  return conversation.post(`${API_CONVERSATION_AUTH}/signout`);
};

const updatePassword = (password) => {
  return conversation.put({ password }, '/auth/signin/updatePassword');
};

export { postAuthLogin, postLogout, updatePassword };
